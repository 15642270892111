<template>
  <div class="loginBox" v-if="isAlive">
    <div class="leftBox" :style="`
      background-image: url(https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/login/%E5%91%A8%E4%B8%80.jpg);
    `">
      <div class="text">
        <h1>Echola System</h1>
        <p>
          The logistics service platform is an online logistics public 
          information service platform established by combining e-commerce 
          mode, Internet technology, logistics technology and other 
          information technologies.</p>
      </div>
      <div class="mask"></div>
    </div>
    <div class="rightBox">
      <div class="center_box">
        <h2>{{ $t('registerLoging.CreateAnAccount') }}</h2>
        <div class="flexAlignCenter">
          <div class="inputBox" style="width: 40%;margin-right: 12px;">
            <div class="felxInput">
              <input
                v-model.trim="ruleForm.username"
                type="text"
                :maxlength="50"
                v-focus
                v-required="{
                  message: 'registerLoging.Username',
                  errorMessage: 'registerLoging.PleaseinputaUserName'
                }"
                autocomplete="off"
              />
            </div>
            <span>{{ $t("registerLoging.Username") }}</span>
          </div>
          <div class="inputBox" style="flex: 1;">
            <div class="felxInput">
              <input
                v-model="ruleForm.email"
                type="text"
                :maxlength="50"
                v-focus
                v-validate="rules.email"
                autocomplete="off"
              />
            </div>
            <span>{{ $t("registerLoging.Email") }}</span>
          </div>
        </div>
        <el-popover
          ref="popover_register"
          width="210"
          placement="left-start"
          trigger="manual"
          v-model="popoverVisible"
        >
          <div class="password_prompt">
            <div class="password_prompt_title">
              {{ $t("login.Passwordshouldbe") }}：
            </div>
            <div
              v-for="(item, index) in [
                {
                  text: $t('login.Atleast'),
                  icon: 'error_icon.png',
                },
                {
                  text: $t('login.Mininumoneuppercase'),
                  icon: 'the_default.png',
                },
                {
                  text: $t('login.Mininum one special character'),
                  icon: 'the_default.png',
                },
              ]"
              :key="index"
              class="password_prompt_strength"
              style="margin-bottom: 17px"
            >
              <img
                :src="`https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/${
                  index + 1 <= verificationLevel
                    ? 'success_icon.png'
                    : item.icon
                }`"
                class="password_prompt_img"
              />
              <span>
                {{ item.text }}
                <span style="color: #71a3d1">
                  {{ index == 0 ? `*${$t("login.necessary")}` : "" }}
                </span>
              </span>
            </div>
          </div>
          <div class="inputBox" slot="reference">
            <div class="felxInput">
              <input
                v-model.trim="ruleForm.password"
                type="text"
                :maxlength="30"
                v-focus
                v-validate="rules.password"
                :class="passwordShow ? 'passwordInput' : ''"
                @blur="popoverVisible = false"
                @focus="popoverVisible = true"
              />
              <i
                class="iconfont" 
                :class="!passwordShow ? 'icon-yincang show' : 'icon-xianshi'" 
                @click="passwordShow = !passwordShow"
              ></i>
            </div>
            <span>{{ $t("login.Password") }}</span>
          </div>
        </el-popover>
        <div class="safetyStrength">
          <div class="lines">
            <p class="lineTip" v-for="(item, index) in 3" :key="index"></p>
          </div>
          <p id="safetyStrength_tips">
            <span>{{ $t('login.Password strength') }}：</span>
            <span class="passTips">{{ $t('login.Tooweak') }}</span>
          </p>
        </div>
        <div class="inputBox">
          <div class="felxInput">
            <input
              v-model.trim="ruleForm.affirmPassword"
              type="text"
              :maxlength="30"
              v-focus
              v-required="{
                message: 'registerLoging.ConfirmPassword',
                errorMessage: 'login.Pleaseinputapassword'
              }"
              :class="passwordShow2 ? 'passwordInput' : ''"
            />
            <i
              class="iconfont" 
              :class="!passwordShow2 ? 'icon-yincang show' : 'icon-xianshi'" 
              @click="passwordShow2 = !passwordShow2"
            ></i>
          </div>
          <span>{{ $t("registerLoging.ConfirmPassword") }}</span>
        </div>
        <div class="inputBox">
          <div class="felxInput">
            <input
              v-model="ruleForm.auth"
              type="text"
              v-focus
              v-required="{
                message: $t('login.authCode'),
                errorMessage: $t('login.Pleaseinputaauthcode')
              }"
              autocomplete="new-text"
            />
            <img
              class="graphical"
              :src="ruleForm.verificationImg"
              @click="acquireVerification"
            />
          </div>
          <span>{{ $t("login.authCode") }}</span>
        </div>
        <p class="registerBox">
          {{ $t("login.Alreadyhaveone") }}
          <span @click="toLogin">
            {{ $t("login.Login") }}
          </span>
        </p>
        <el-button
          type="primary" 
          :loading="loadingGetStart" 
          :disabled="submitDisabled" 
          class="buttonBox"
          @click="submitForm('ruleForm')"
        >{{ $t("registerLoging.CreateYourAccount") }}</el-button>
      </div>
      <div class="translateButton">
        <el-tabs 
          class="special_tabs" 
          v-model="translate_type"
          @tab-click="translateChange">
          <el-tab-pane label="中" name="zh" />
          <el-tab-pane label="En" name="en" />
        </el-tabs>
      </div>
    </div>
    <toVerify
      :toVerifyShow.sync="toVerifyShow"
      :loadingButton="loadingButton"
      @handleClose="toVerifyClose"
      :email="ruleForm.email"
      :timing="timing"
      @resend="resend"
    />
  </div>
</template>

<script>
import { 
  getCaptcha, 
  getPublicKey, 
  registerUser
} from "@/api/index";
import Config from "@/store/Config";
import toVerify from "@/components/toVerify/toVerify";
import { rsaData } from "@/utils/encrypt";

export default {
  name: "register",
  components: {
    toVerify
  },
  data() {
    return {
      translate_type: "zh",
      loginLoading: false,
      passwordShow: true,
      passwordShow2: true,
      loadingButton: false,
      toVerifyShow: false,
      loadingGetStart: false,
      timing: -1,
      rules: {
        email: [{
          type: 'email',
          message: "registerLoging.Email",
          errorMessage: 'registerLoging.PleaseinputaEmail'
        }],
        password: [{
          type: 'password',
          message: "login.Password",
          errorMessage: 'login.Pleaseinputapassword'
        }]
      },
      ruleForm: {
        username: "",
        email: "",
        password: "",
        affirmPassword: "",
        auth: "",
        verificationImg: "",
      },
      isAlive: true,
      verificationLevel: -1,
      popoverVisible: false,
    };
  },
  computed: {
    submitDisabled() {
      return !(
        this.ruleForm.username && 
        this.ruleForm.email && 
        this.ruleForm.password && 
        this.ruleForm.affirmPassword && 
        this.ruleForm.auth
      )
    }
  },
  watch: {
    "ruleForm.password"(val) {
      let p = document.querySelectorAll(".safetyStrength .lineTip");
      let span = document.querySelector("#safetyStrength_tips .passTips");
      if (val.length >= 8 && /[a-zA-Z]/.test(val) && /[0-9]/.test(val)) {
        span.style.display = "block";
        if (/[a-zA-Z]/.test(val)) {
          span.innerHTML = this.$t("login.Tooweak");
          span.style.color = "#F56C6C";
          p[0].style.backgroundColor = "#F56C6C";
          p[1].style.backgroundColor = "#F7F9FC";
          p[2].style.backgroundColor = "#F7F9FC";
          this.verificationLevel = 1;
          if (
            /[~!@#$%^&*()_+<>?:{},.`|]/.test(val) ||
            (/[a-z]/.test(val) && /[A-Z]/.test(val))
          ) {
            span.innerHTML = this.$t("login.secondary");
            span.style.color = "#FF8D1A";
            p[0].style.backgroundColor = "#FF8D1A";
            p[1].style.backgroundColor = "#FF8D1A";
            p[2].style.backgroundColor = "#F7F9FC";
            this.verificationLevel = 2;
            if (
              /[a-z]/.test(val) &&
              /[A-Z]/.test(val) &&
              /[~!@#$%^&*()_+<>?:{},.`|]/.test(val)
            ) {
              span.innerHTML = this.$t("login.senior");
              span.style.color = "#48AC42";
              p[0].style.backgroundColor = "#48AC42";
              p[1].style.backgroundColor = "#48AC42";
              p[2].style.backgroundColor = "#48AC42";
              this.verificationLevel = 3;
            }
          }
        }
      } else {
        span.innerHTML = "";
        p[0].style.backgroundColor = "#dfe2e6";
        p[1].style.backgroundColor = "#dfe2e6";
        p[2].style.backgroundColor = "#dfe2e6";
        this.verificationLevel = -1;
      }
    },
  },
  mounted() {
    window.vm.$i18n.locale = this.$store.state.user.language;
    this.translate_type = this.$store.state.user.language;
    this.acquireVerification();
  },
  methods: {
    // 监听翻译按钮
    translateChange() {
      this.$i18n.locale = this.translate_type;
      this.$store.dispatch("user/setLanguage", this.translate_type);
      this.isAlive = false;
      this.$nextTick(function(){
        this.isAlive = true;
      })
      for(let i in this.ruleForm) {
        if(i === 'verificationImg') return;
        this.ruleForm[i] = ""
      }
    },
    //获取验证码
    acquireVerification() {
      getCaptcha()
        .then((response) => {
          if (!response) return;
          this.ruleForm.verificationImg = URL.createObjectURL(response);
        })
    },
    isFormInvalid(dom) {
      let isValid = false;
      dom.forEach((item) => {
        if(item.classList.contains('invalid')) {
          isValid = true;
        }
      })
      return isValid;
    },
    submitForm(formName) {
      const inputDom = document.querySelectorAll(".inputBox");
      if(this.isFormInvalid(inputDom)) return;
      if(this.ruleForm.password != this.ruleForm.affirmPassword) {
        this.$message.error(this.$t('login.PasswordInconsistency'));
        return;
      }
      this.cry_2_reg();
    },
    //密码加密并注册
    cry_2_reg() {
      getPublicKey()
        .then((response) => {
          if (!response) return;
          this.publickey = response;
          //调用注册接口
          if (false == Config.IS_DEBUG) {
            this.register(rsaData(this.ruleForm.password, this.publickey));
          } else {
            this.register(this.ruleForm.password);
          }
        })
        .catch((error) => {});
    },
    //调用注册接口
    register(password) {
      if(this.ruleForm.username.length < 2) {
        this.$message.warning(this.$t('registerLoging.Account name with a minimum of 2 digits'))
        return;
      }
      var data = {
        username: this.ruleForm.username,
        password,
        email: this.ruleForm.email,
        captcha: this.ruleForm.auth,
        token: this.$route.query.token || "",
      };
      this.loadingGetStart = true;
      registerUser(data)
        .then(() => {
          if (!this.$route.query.token) {
            this.toVerifyShow = true;
            //此处调用邮箱验证的dialog
            clearInterval(window["timing"]);
            this.timing = 30;
            window["timing"] = setInterval(() => {
              this.timing--;
              this.timing < 0 && clearInterval(window["timing"]);
            }, 1000);
            this.$message.success(
              this.$t("xiaoxie.Please activate your account via email")
            );
          } else {
            setTimeout(() => {
              this.$message.success(this.$t("xiaoxie.register successfully"));
            }, 1000);
            this.$router.push({
              path: "/login",
              query: {
                username: this.ruleForm.username,
              },
            });
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingButton = false;
          this.loadingGetStart = false;
        });
    },
    //重新发送邮箱
    resend() {
      if (this.timing <= 0) {
        this.loadingButton = true;
        this.cry_2_reg();
      }
    },
    //关闭dialog操作
    toVerifyClose() {
      this.toVerifyShow = false;
    },
    // 跳转登录页面
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./style/index.less";
</style>

<style>
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #757d8a !important;
  -webkit-background-clip: text !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
</style>